<template>
  <b-card>
    <div>
      <template>
        <b-row class="mb-1 justify-content-center">
          <b-col
            class="align-self-center"
          >
            <div class="d-flex align-items-center mr-2 justify-content-center">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h4><strong>
                  {{ $t('superQuestion') }}</strong></h4>
                <b-badge variant="light-primary">
                  {{ $t('Position') }} {{ fatherPosition }}
                </b-badge>
              </div>
            </div>
          </b-col>

          <b-col>
            <b-list-group>
              <b-list-group-item><strong>{{ $t('Question') }} : </strong>{{ fatherTitle }}</b-list-group-item>
              <b-list-group-item><strong>{{ $t('Detail') }} : </strong>{{ fatherContent }}</b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col>
            <b-list-group>
              <b-list-group-item><strong>{{ $t('Position') }} : </strong>{{ fatherPosition }}</b-list-group-item>
              <b-list-group-item active>
                <strong>{{ $t('selectedAnswer') }} : </strong>{{ fatherAnswerChosen }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

      </template>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="respDataForm"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Categories -->
        <template #cell(categoriesFormSurveyId)="data">
          <span class="align-middle ml-50">{{ data.item.categoriesFormSurveyId.title }}</span>
        </template>

        <!-- Column: Answer -->
        <template #cell(answer)="data">
          <b-button-group>
            <b-button
              v-for="(item, index) in data.item.answer"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="align-middle"
              :variant="data.item.answer[index].page!=null ? 'primary':'outline-primary'"
              @click="addChild(data.item._id, index)"
            >{{ item.name }}</b-button>
          </b-button-group>
        </template>

        <!-- Column: Action -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <!-- Edit & Delete -->
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="editForm(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('EDIT') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteForm( data.item._id )">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('DELETE') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div>
        <validation-observer ref="formData">
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-row>
              <!-- Title -->
              <b-col
                md="4"
              >
                <b-form-group
                  :label="$t('Title')"
                  label-for="title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Title')"
                      :disabled="!!respDataForm.length && dataId == null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Content อธิบายคำถาม -->
              <b-col
                md="4"
              >
                <b-form-group
                  :label="$t('Content')"
                  label-for="content"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="content"
                    rules="required"
                  >
                    <b-form-input
                      id="content"
                      v-model="content"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Content')"
                      :disabled="!!respDataForm.length && dataId == null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Row Loop เพิ่มคำตอบเรื่อย ๆ -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <!-- Answer.name -->
              <b-col md="2">
                <b-form-group
                  :label="$t('Answer')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="item.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Answer')"
                      :disabled="!!respDataForm.length && dataId == null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Answer.accessories -->
              <b-col md="5">
                <b-form-group
                  :label="$t('Accessories')"
                  label-for="accessories"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="accessories"
                  >
                    <v-select
                      v-model="item.accessories"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      :options="respDataCarAcc"
                      :reduce="(respDataCarAcc) => respDataCarAcc._id"
                      label="name"
                      :placeholder="$t('PleaseSelect')"
                      :disabled="!!respDataForm.length && dataId == null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  :disabled="!!respDataForm.length && dataId == null"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('Delete') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <div v-show="!respDataForm.length || dataId !== null">
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="repeateAgain()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('addAnswer') }}</span>
          </b-button>
        </div>
        <div
          class="d-flex mt-2"
        >
          <b-button
            variant="primary"
            class="mr-2"
            @click="validationForm()"
          >
            {{ dataId !== null ? $t('Edit') : $t('SUBMIT') }}
          </b-button>
        </div>
      </div>
    </div></b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTable, BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BButtonGroup, BDropdown, BDropdownItem,
  BAvatar, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '../../../store/root/formSurvey'

const STORE_MODULE_NAME = 'formSurvey'

export default {
  components: {
    BCard,
    BTable,
    BForm,
    BDropdown,
    BDropdownItem,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BFormGroup,
    BFormInput,
    BAvatar,
    BBadge,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      fatherTitle: '',
      fatherContent: '',
      fatherPosition: '',
      fatherAnswerChosen: '',
      items: [{
        // id: 1,
      }],
      dataId: null,
      page: null,
      fatherFormSurveyId: null,
      title: '',
      content: '',
      indexAnswer: null,
      nextTodoId: 2,
      required,
      EditStatus: false,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'categoriesFormSurveyId', label: this.$t('Categories'), sortable: false },
        { key: 'title', label: this.$t('Title'), sortable: false },
        { key: 'content', label: this.$t('Content'), sortable: false },
        { key: 'position', label: this.$t('Position'), sortable: false },
        { key: 'answer', label: this.$t('Answer'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    // FormSurvey
    respDataForm() {
      return store.state.formSurvey.respDataForm || []
    },
    // CarAcc
    respDataCarAcc() {
      return store.state.formSurvey.respDataCarAcc.data
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    const Id = this.$route.params.id
    this.fatherFormSurveyId = Id
    const Index = this.$route.params.index
    this.indexAnswer = Index
    window.addEventListener('resize', this.initTrHeight)

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    // viewFormSurvey
    store
      .dispatch(`${STORE_MODULE_NAME}/viewFormSurvey`, Id)
      .then(result => {
        this.fatherTitle = result.data.data.title
        this.fatherContent = result.data.data.content
        this.fatherPosition = result.data.data.position
        this.fatherAnswerChosen = result.data.data.answer[Index].name
        if (result.data.data.answer[Index].page !== null) {
          // viewChildren
          store
            .dispatch(`${STORE_MODULE_NAME}/viewChildren`, result.data.data.answer[Index].page)
            .then(result2 => {
              console.log('fetchCarAcc Success : ', result2)
              this.showToast('success', 'bottom-right', result2.data.message, 'CheckIcon', 'เรียกข้อมูลสำเร็จ')
            }).catch(error => {
              console.log('fetchCarAcc Error : ', error)
              this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เรียกข้อมูลไม่สำเร็จ')
            })
        }
        console.log('fetchCarAcc Success : ', result)
        this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'เรียกข้อมูลสำเร็จ')
      }).catch(error => {
        console.log('fetchCarAcc Error : ', error)
        this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เรียกข้อมูลไม่สำเร็จ')
      })
    // getCarAcc
    store
      .dispatch(`${STORE_MODULE_NAME}/getAccessories`)
      .then(result => {
        console.log('fetchCarAcc Success : ', result)
        this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'เรียกข้อมูลสำเร็จ')
      }).catch(error => {
        console.log('fetchCarAcc Error : ', error)
        this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เรียกข้อมูลไม่สำเร็จ')
      })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addChild(item, index) {
      this.$router.push({
        path: `/root/formsurvey/addchild/${item}/${index}`,
      })
      window.location.reload()
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            fatherFormSurveyId: this.fatherFormSurveyId,
            indexAnswerFather: this.indexAnswer,
            title: this.title,
            content: this.content,
            answer: this.items,
          }
          console.log(obj)
          if (this.dataId !== null) {
            store
              .dispatch(`${STORE_MODULE_NAME}/editFormSurvey`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'แก้ไขข้อมูลสำเร็จ')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'แก้ไขข้อมูลไม่สำเร็จ')
              })
          } else {
            delete obj._id
            store
              .dispatch(`${STORE_MODULE_NAME}/addChildrenFormSurvey`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'เพิ่มข้อมูลสำเร็จ')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เพิ่มข้อมูลไม่สำเร็จ')
              })
          }
        }
      })
    },
    editForm(item) {
      this.dataId = item._id
      this.title = item.title
      this.content = item.content
      this.items = item.answer
      for (let index = 1; index < item.answer.length && this.EditStatus === false; index += 1) { this.trAddHeight(this.$refs.row[0].offsetHeight) }
      this.EditStatus = true
    },
    deleteForm(id) {
      store
        .dispatch(`${STORE_MODULE_NAME}/deleteFormSurvey`, id)
        .then(result => {
          console.log('Delete Success', result)
          this.showToast('success', 'top-right', 'Success', 'CheckIcon')
        }).catch(err => {
          console.log('Delete Failed', err)
          this.showToast('danger', 'top-right', 'Error ', 'XCircleIcon')
        })
    },
    repeateAgain() {
      this.items.push({
        // id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
</style>
